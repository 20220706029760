export default {
  data() {
    return {
      icons: [
        {
          symbol: {
            path: 7,
            opts: {
              scale: 0.6, // 图标缩放大小
              strokeColor: 'green', // 设置矢量图标的线填充颜色
              strokeWeight: 2 // 设置线宽
            }
          },
          offset: '100%',
          repeat: '100%',
          fixedRotation: false
        }
      ]
    }
  },

  methods: {
    // 重置地图覆盖物
    resetMap() {
      console.log('resetMap')
      // this.map.clearOverlays()
    }
  }
}
