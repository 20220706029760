<script>
import commonMixin from './base/mixins/common.js'
import bindEvents from './base/bindEvent.js'
import { createPoint, createIconSequence } from './base/factory.js'
// 折线
export default {
  name: 'BMapPolyline',
  mixins: [commonMixin('overlay')],
  props: {
    path: {
      type: Array,
      default: () => {}
    },
    strokeColor: {
      type: String,
      default: () => {}
    },
    strokeWeight: {
      type: Number,
      default: () => {}
    },
    strokeOpacity: {
      type: Number,
      default: () => {}
    },
    strokeStyle: {
      type: String,
      default: () => {}
    },
    massClear: {
      type: Boolean,
      default: true
    },
    clicking: {
      type: Boolean,
      default: true
    },
    editing: {
      type: Boolean,
      default: false
    },
    icons: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    // 坐标数组发生变化
    path: {
      handler(val, oldVal) {
        this.reload()
      },
      deep: true
    },
    // icon数组发生变化
    icons: {
      handler(val, oldVal) {
        this.reload()
      },
      deep: true
    },
    strokeColor(val) {
      this.originInstance.setStrokeColor(val)
    },
    strokeOpacity(val) {
      this.originInstance.setStrokeOpacity(val)
    },
    strokeWeight(val) {
      this.originInstance.setStrokeWeight(val)
    },
    strokeStyle(val) {
      this.originInstance.setStrokeStyle(val)
    },
    editing(val) {
      val ? this.originInstance.enableEditing() : this.originInstance.disableEditing()
    },
    massClear(val) {
      val ? this.originInstance.enableMassClear() : this.originInstance.disableMassClear()
    },
    clicking(val) {
      this.reload()
    }
  },
  computed: {
    iconSequences() {
      const { BMap, icons } = this
      return icons.map(item => {
        return createIconSequence(BMap, item)
      })
    }
  },
  methods: {
    load() {
      const {
        BMap,
        map,
        path,
        strokeColor,
        strokeWeight,
        strokeOpacity,
        strokeStyle,
        editing,
        massClear,
        clicking,
        iconSequences
      } = this
      // 创建折线
      // createPoint 创建点，点间连线
      if (path.length === 0) {
        return
      }
      const overlay = new BMap.Polyline(
        path.map(item => createPoint(BMap, { lng: item.lng, lat: item.lat })),
        {
          // 折线属性设置
          strokeColor,
          strokeWeight,
          strokeOpacity,
          strokeStyle,
          enableEditing: editing,
          enableMassClear: massClear,
          enableClicking: clicking,
          icons: iconSequences
        }
      )
      this.originInstance = overlay
      // 地图上添加折线覆盖物
      map.addOverlay(overlay)
      bindEvents.call(this, overlay)
    }
  }
}
</script>
