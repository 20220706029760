export function createPoint(BMap, options = {}) {
  const { lng, lat } = options
  return new BMap.Point(lng, lat)
}

export function createPixel(BMap, options = {}) {
  const { x, y } = options
  return new BMap.Pixel(x, y)
}

export function createBounds(BMap, options = {}) {
  const { sw, ne } = options
  return new BMap.Bounds(createPoint(BMap, sw), createPoint(BMap, ne))
}
// 设置宽与高属性
export function createSize(BMap, options = {}) {
  const { width, height } = options
  return new BMap.Size(width, height)
}

export function createIcon(BMap, options = {}) {
  const { url, size, opts = {} } = options
  // 创建图标
  // createSize 创建图标大小
  return new BMap.Icon(url, createSize(BMap, size), {
    // 指定定位位置
    // 当标注显示在地图上时，其所指向的地理位置距离图标左上
    // 角各偏移10像素和25像素。您可以看到在本例中该位置即
    // 图标中央下端的尖角位置
    anchor: opts.anchor && createSize(BMap, opts.anchor),
    imageSize: opts.imageSize && createSize(BMap, opts.imageSize),
    // 设置图片偏移
    // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您
    // 需要指定大图的偏移位置，此做法与css sprites技术类似。
    imageOffset: opts.imageOffset && createSize(BMap, opts.imageOffset),
    infoWindowAnchor: opts.infoWindowAnchor && createSize(BMap, opts.infoWindowAnchor),
    printImageUrl: opts.printImageUrl
  })
}

// 创建文本标注对象
export function createLabel(BMap, options = {}) {
  const { content, opts } = options
  return new BMap.Label(content, {
    offset: opts.offset && createSize(BMap, opts.offset),
    position: opts.position && createPoint(BMap, opts.position),
    enableMassClear: opts.enableMassClear
  })
}

export function createSymbol(BMap, options = {}) {
  const { path, opts } = options
  return new BMap.Symbol(global[path] || path, {
    anchor: opts.anchor && createSize(BMap, opts.anchor),
    // fillColor: opts.fillColor,
    // fillOpacity: opts.fillOpacity,
    scale: opts.scale,
    rotation: opts.rotation,
    strokeColor: opts.strokeColor,
    strokeOpacity: opts.strokeOpacity,
    strokeWeight: opts.strokeWeight
  })
}

export function createIconSequence(BMap, options = {}) {
  const { symbol, offset, repeat, fixedRotation } = options
  return new BMap.IconSequence(symbol && createSymbol(BMap, symbol), offset, repeat, fixedRotation)
}
